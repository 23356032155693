import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

export default ({ data }) => (
	<Layout>
		<SEO title="InVision — Ryan Teuscher" />
		<nav>
			<Link to="/">
				<span className="material-icons">arrow_back</span>
			</Link>
		</nav>
		<header>
			<h1>InVision. Products at the heart of design.</h1>
			<h3>Product Manager & Designer</h3>
			<p>
				Great design comes from understanding customers&mdash;and the business.
				Team{" "}
				<a href="http://waybury.com" target="_blank" rel="noopener noreferrer">
					Waybury's
				</a>{" "}
				move to{" "}
				<a href="http://invision.com" target="_blank" rel="noopener noreferrer">
					Invision
				</a>{" "}
				provided the opportunity to grow as a product thinker by putting
				understanding into practice at scale. As a design-driven product
				manager, I wrangled systemic challenges from defining sharing across
				product lines to creating integrations with Atlassian, now one of
				Invision's strategic partners and investors.
			</p>
		</header>
		<section>
			<figure>
				<Image
					alt="Distributed teammwork"
					filename="slide-invision-video.png"
				/>
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Spaces" filename="slide-invision-spaces.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Search" filename="slide-invision-search.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Design visibility" filename="slide-invision-viz.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Invision for JIRA" filename="slide-invision-jira.png" />
			</figure>
		</section>
	</Layout>
)
